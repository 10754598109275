const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-6 pt-5 pt-md-8">
      </div>
    </>
  );
};

export default Header;
