import { useState } from "react";
//
import AsyncSelect from 'react-select/async';
import {
  Card,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";

// core components

import Header from "components/Headers/Header.js";
import { toMoney } from "utils";
import api from "../api"

const Index = (props) => {
  const [modal, setModal] = useState(false);
  const [forwardingModal, setForwardingModal] = useState({})
  const [loading, setLoading] = useState(false)

  const toggle = () => {

    if (modal === true) {
      setTimeout(() => {
        setLoading(false)
        setForwardingModal({})
      }, 500)
    }
    return setModal(!modal);
  }

  const confirmeforwarding = (id) => {
    const confirmed_at = new Date().toLocaleString("pt-BR")
    api.put(`forwarding/${id}/`, { confirmed_at }).then((response) => {
      console.log(`confirmado ${id}`)
    })
  }

  function handleProcedureSelected(forwarding) {
    api.get(`forwarding/${forwarding.value}`).then((response) => {
      setLoading(true)
      setForwardingModal(response.data)
      confirmeforwarding(response.data.id)
      setLoading(false)
      toggle()
    })
  }

  const loadOptionsProcedure = (inputValue, callback) => {
    api.get('forwarding/', { params: { code: inputValue } }).then((response) => {
      const options = response.data.results.map(product => (
        { value: product.id, label: product.patient_name + ' ' + product.patient_last_name }
      ))
      callback(options)
    })
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col xl="12">
            <Card className="shadow">
              <Form>
                <AsyncSelect
                  onChange={(e) => { handleProcedureSelected(e) }}
                  defaultOptions={[]}
                  noOptionsMessage={() => "Sem resultados"}
                  isSearchable
                  value={"" || null}
                  placeholder="Pesquisar Guia"
                  cacheOptions
                  loadOptions={loadOptionsProcedure}
                />
              </Form>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ 'marginBottom': '-60px', width: "100%" }} toggle={toggle}><p className="h3">Dados para confirmação</p></ModalHeader>
          <ModalBody>
            <hr />
            {loading &&
              <div className="text-center">
                <Spinner>
                </Spinner>
                <p>Carregando...</p>
              </div>
            }
            {forwardingModal &&
              <div style={{ 'marginTop': '-10px' }}>
                <h3>Procedimento</h3>
                <ul>
                  <li>Nome: {forwardingModal.procedure_provider?.procedure?.name}</li>
                  <li>Agendado para {new Date(forwardingModal.scheduled_at).toLocaleString('pt-br')}</li>
                  <li>Notas: {forwardingModal.procedure_provider?.procedure?.notes}</li>
                  <li>Instrução: {forwardingModal.procedure_provider?.procedure?.instructions}</li>
                  <li>Agendado com: {forwardingModal.scheduled_person}</li>
                  <li>Preço <strong>R$:{forwardingModal.amount_sell && toMoney(forwardingModal.amount_sell)}</strong></li>
                </ul>
                <h3>Paciente</h3>
                <ul>
                  <li>Nome: {forwardingModal.patient?.name}</li>
                  <li>CPF: {forwardingModal.patient?.cpf}</li>
                  <li>Telefone: {forwardingModal.patient?.phone}</li>
                  <li>Email: {forwardingModal.patient?.email}</li>
                  <li>Endereço: {forwardingModal.patient?.street} {forwardingModal.patient?.number} {forwardingModal.patient?.district}</li>
                  <li>CEP: {forwardingModal.patient?.zip_code}</li>
                  <li>Estado: {forwardingModal.patient?.state}</li>
                  <li>Cidade: {forwardingModal.patient?.city}</li>
                  <li>Complemento: {forwardingModal.patient?.complement}</li>
                  <li>Numero: {forwardingModal.patient?.number}</li>
                </ul>
                <h3>Clinica</h3>
                <ul>
                  <li>Nome: {forwardingModal.clinic?.name}</li>
                  <li>Telefone: {forwardingModal.clinic?.phone1}</li>
                  <li>Email: {forwardingModal.clinic?.email}</li>
                  <li>Encaminhado por: {forwardingModal.created_by?.name} {forwardingModal.created_by?.last_name}</li>
                </ul>
              </div>
            }
            <hr />
          </ModalBody >
          <ModalFooter style={{ "marginTop": '-50px', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <strong>SAC: 3442-1411</strong>
          </ModalFooter>
        </Modal >
      </Container>
    </>
  );
};

export default Index;
