import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { GlobalContext } from "../../GlobalContext";
import "@fortawesome/fontawesome-free/css/all.min.css";

const AdminNavbar = (props) => {

  const { userConfig, } = React.useContext(GlobalContext);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    props.history.push('/auth/login');
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <p className="text-white mt-3">{userConfig.provider_first_name + " " + userConfig.provider_last_name}</p>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-lg-block">
                    <i className="ni ni-single-02 text-white" style={{ fontSize: "20px" }}></i>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* <DropdownItem className="noti-title" header tag="div"> */}
                {/*   <h6 className="text-overflow m-0">Welcome!</h6> */}
                {/* </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}> */}
                {/*   <i className="ni ni-single-02" /> */}
                {/*   <span>My profile</span> */}
                {/* </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}> */}
                {/*   <i className="ni ni-settings-gear-65" /> */}
                {/*   <span>Settings</span> */}
                {/* </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}> */}
                {/*   <i className="ni ni-calendar-grid-58" /> */}
                {/*   <span>Activity</span> */}
                {/* </DropdownItem> */}
                {/* <DropdownItem to="/admin/user-profile" tag={Link}> */}
                {/*   <i className="ni ni-support-16" /> */}
                {/*   <span>Support</span> */}
                {/* </DropdownItem> */}
                {/* <DropdownItem divider /> */}
                <DropdownItem href="#pablo" onClick={(e) => logout(e)}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
