import React from "react";

export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const [userConfig, SetUserConfig] = React.useState({});
  React.useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
      SetUserConfig(items);
    }
  }, []);

  const saveUserConfig = (items) => {
    localStorage.setItem('items', JSON.stringify(items));
    SetUserConfig(items);
  };

  return (
    <GlobalContext.Provider value={{ userConfig, saveUserConfig }}>
      {children}
    </GlobalContext.Provider>
  );
}

