import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


import { GlobalProvider } from './GlobalContext';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";


const App = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('access_token');
    setLoading(false)
    if (!token) {
      if (window.location.pathname !== "/auth/login/") {
        window.location.href = '/auth/login/';
      }
    }
  }, [])

  if (loading) {
    return (
      <h1>Carregando</h1>
    )
  }
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
