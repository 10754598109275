import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer" style={{ "position": "fixed", "bottom": 0, "width": "100%" }}>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            Aux. Saúde
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
