import React from "react";
import { useState } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../api'
import { GlobalContext } from "../../GlobalContext";

const schema = yup.object().shape({
  email: yup.string().email("Digite um email válido").required("Digite um email"),
  password: yup.string().required("Insira sua senha"),
});

const Login = () => {

  const history = useHistory();
  const [, setShowError] = useState(false)
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const userConfig = React.useContext(GlobalContext);

  const onSubmit = (data) => {
    // setShowError(false)
    api.post(`token/`, {
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        localStorage.setItem('access_token', res.data.access);
        localStorage.setItem('refresh_token', res.data.refresh);
        api.defaults.headers['Authorization'] =
          'JWT ' + localStorage.getItem('access_token');
        api.get(`me`).then((res) => {
          userConfig.saveUserConfig(res.data);
        })
        history.push('/');
        setShowError(false)
        // toast.closeAll()
        reset()
      })
      .catch(error => {
        if (error.response.statusText === "Unauthorized") {
          console.log(error.response)
        }
      });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Entre com seu email e senha</small>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    id='email'
                    className="form-control"
                    placeholder='Digite seu email'
                    {...register("email")}
                  />
                </InputGroup>
                <p style={{ "color": "red" }} className="mt-4">{errors.email?.message}</p>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    className="form-control"
                    placeholder="Senha"
                    type="password"
                    {...register("password")}
                  />
                </InputGroup>
                <p style={{ "color": "red" }} className="mt-4">{errors.password?.message}</p>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Entrar
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          {/* <Col xs="6"> */}
          {/*   <a */}
          {/*     className="text-light" */}
          {/*     href="#pablo" */}
          {/*     onClick={(e) => e.preventDefault()} */}
          {/*   > */}
          {/*     <small>Forgot password?</small> */}
          {/*   </a> */}
          {/* </Col> */}
          {/* <Col className="text-right" xs="6"> */}
          {/*   <a */}
          {/*     className="text-light" */}
          {/*     href="#pablo" */}
          {/*     onClick={(e) => e.preventDefault()} */}
          {/*   > */}
          {/*     <small>Create new account</small> */}
          {/*   </a> */}
          {/* </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default Login;
