import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL; //  'http://127.0.0.1:8000/api/'

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    Authorization: localStorage.getItem('access_token')
      ? 'JWT ' + localStorage.getItem('access_token')
      : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
      alert('Um erro com o servidor ou internet ocorreu. ');
      window.location.href = '/auth/login/';
      return Promise.reject(error);
    }

    if (
      error.response.status === 403 &&
      originalRequest.url === baseURL + 'token/refresh/'
    ) {
      window.location.href = '/login/';
      return Promise.reject(error);
    }

    if (
      error.response.status === 403
    ) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken === "undefined") {
        console.log('Refresh token not available.');
        window.location.href = '/auth/login/';
      }
      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.access);

              axiosInstance.defaults.headers['Authorization'] =
                'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now);
          window.location.href = '/auth/login';
        }
      } else {
        console.log('Refresh token not available.');
        window.location.href = '/auth/login/';
      }
    }
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
